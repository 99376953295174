import React from "react";
import { Grid, Typography } from "@mui/material";
import { Pad, HorseSaddle } from "../types/Horses";
import { concatIfBothNotEmpty } from "../helpers/utils";

const HorseViewSaddleComponent = (props: {
  saddle: HorseSaddle;
  index: number;
}) => {
  const { saddle, index } = props;

  const pads: Pad[] = [
    "Ogilvy",
    "Wool",
    "Lamicell",
    "Prolite",
    "Half pad",
    "Riser",
    "Thinline",
    "Foam",
  ];

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{
        border: "2px solid #ddd",
        borderRadius: "4px",
        marginTop: "0.5em",
        marginBottom: "0.5em",
      }}
    >
      <Grid item xs={1} style={{ paddingBottom: "1em" }}>
        <Typography variant="h6"> {index + 1}</Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="column" style={{ paddingBottom: "1em" }}>
          <Grid item>
            <Typography variant="body1">
              {!saddle.pads && !saddle.additions
                ? saddle.saddle
                : `${saddle.saddle} w/${concatIfBothNotEmpty(
                    saddle.pads.toLowerCase(),
                    saddle.additions.toLowerCase()
                  )}`}
            </Typography>
          </Grid>
          {saddle.priorityFit ? (
            <Grid item>
              <Typography variant="body1">Gets saddle priority</Typography>
            </Grid>
          ) : null}
          {saddle.smallSaddle ? (
            <Grid item>
              <Typography variant="body1">This is a small saddle</Typography>
            </Grid>
          ) : null}
          {saddle.notes ? (
            <Grid item>
              <Typography variant="body1">{saddle.notes}</Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HorseViewSaddleComponent;
